import { Box, Button, Card, Loader, MarketingPageLayout, MarketingPageLayoutContent, Page, WixDesignSystemProvider, Image } from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import HeroImage from './hero.png';
import { ReactTagManager } from 'react-gtm-ts';
import { useTranslation } from "react-i18next";
import i18n from "./i18n"
const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}


function App() {

  const { t } = useTranslation();
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=c0d59f1e-839b-4807-91d6-840e400279bb&redirectUrl=https://certifiedcode.wixsite.com/forward/_functions/@certifiedcode/base-backend/auth`;
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text={t('loading')} />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
    <Card>
      <MarketingPageLayout
        removeImageHorizontalPadding
        removeImageVerticalPadding
        content={
          <Box height="840px" verticalAlign="middle">
            <MarketingPageLayoutContent
              title={t('forward-title')}
              content= {t('forward-content')}
              actions={<Box direction="horizontal" gap="SP1">
                {/* <Button onClick={() => { window.Intercom("showArticle", 8100023) }}>See how</Button> */}
                <Button skin='inverted' as='a' href='https://bridget.reviews.certifiedcode.us/c0d59f1e-839b-4807-91d6-840e400279bb' target='_blank'>{t('forward-rate-button')}</Button></Box>}
            />
          </Box>
        }
        image={<Image borderRadius={0} src={HeroImage} />}
      />
    </Card>
  </WixDesignSystemProvider >
}

export default App;
